import React, { useState, createContext } from 'react'

const SearchContext = createContext<{
  searchTerm: string
  addSearchTerm: (term: string) => void
  clearSearchTerm: () => void
}>({ searchTerm: '', addSearchTerm() {}, clearSearchTerm() {} })

const SearchProvider: React.FC = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const addSearchTerm = (term: string) => {
    setSearchTerm(term)
  }

  const clearSearchTerm = () => {
    setSearchTerm('')
  }

  return (
    <SearchContext.Provider
      value={{ searchTerm, addSearchTerm, clearSearchTerm }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export { SearchContext, SearchProvider }
