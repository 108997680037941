import './src/styles/main.scss'

import React from 'react'

import { ModalProvider } from '@islandsstofa/react'
import { SearchProvider } from '@src/context/searchContext'
import { MobileMenuProvider } from '@src/context/mobileMenuContext'

const wrapRootElement = ({ element }) => (
  <SearchProvider>
    <ModalProvider>
      <MobileMenuProvider>{element}</MobileMenuProvider>
    </ModalProvider>
  </SearchProvider>
)

export { wrapRootElement }
